<script setup lang="ts">
import type { Promotion } from "@/types";

defineProps<{
	type?: Promotion["type"];
}>();

const { t } = useT();
const { open } = useNlcModals();
const { canPlayVault } = usePrizeVaultData();
const { data: appInitData } = useAppInitData();

const buttonName = computed(() => {
	if (appInitData.value?.isGuest) {
		return t("prizeVault.promo.button.guest");
	}
	if (canPlayVault.value) {
		return t("prizeVault.promo.button.play");
	}
	return t("prizeVault.promo.button.cash");
});

const handleClick = () => {
	if (canPlayVault.value) {
		open("LazyOModalPrizeVaultPlay");
		return;
	}
	window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
};

const handleClickInfo = () => {
	open("LazyOModalPrizeVaultHowItWorks");
};
</script>

<template>
	<MPromotionWrapper
		:type="type"
		:title="t('prizeVault.promo.title')"
		image="/nuxt-img/prize-vault/promo.jpg"
		buttonInfo
		:buttonName="buttonName"
		@click-info="handleClickInfo"
		@click-card="handleClick"
	>
		<AText :modifiers="['bold', 'center']">
			{{ t("prizeVault.promo.description") }}
		</AText>

		<LazyMPrizeVaultProgressBar />
	</MPromotionWrapper>
</template>

<style scoped lang="scss">
:deep(.title) {
	margin-bottom: -12px;
}
.progress-bar {
	margin-top: 26px;
}
</style>
